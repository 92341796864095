<template>
  <div class="edit-plant-photo">
    <b-form-input
        v-model="plantPhoto.author"
        class="w-100"
        placeholder="Auteur"
        type="text"/>
    <br>

    <b-form-input
        v-model="plantPhoto.source"
        class="w-100"
        placeholder="Source"
        type="text"/>
    <br>

    <b-form-input
        v-model="plantPhoto.license"
        class="w-100"
        placeholder="Licence"
        type="text"/>
    <br>

    <b-form-input
        v-model="plantPhoto.order"
        class="w-100"
        placeholder="Ordre"
        type="number"/>
    <br>

    <b-form-select
        v-model="plantPhoto.manual_status"
        :options="manual_status_options"
        class="mb-1"/>

    <div class="vx-row justify-around items-center mt-1">

      <img v-if="plantPhoto.photo" :src="plantPhoto.photo.url" style="max-width: 100%; max-height: 250px; margin-bottom: 5px;"/>

      <b-button variant="outline-primary" @click="promptUploadPhoto = true">
        {{ plantPhoto.photo ? 'Modifier la photo' : 'Ajouter une photo' }}
      </b-button>
    </div>

    <div class="d-flex justify-content-end mt-1">
      <b-button variant="primary" @click="edit">
        Valider
      </b-button>
    </div>

    <JayUpload :active.sync="promptUploadPhoto"
        title="Changer de photo"
        @on-cancel="plantPhoto.photo = null"
        @on-error="plantPhoto.photo = null"
        @on-success="addPhoto"/>
  </div>
</template>

<script type="text/javascript">
import JayUpload from '@/components/global/JayUpload'
// We use directly axios instead of the store for simplicity
import axios from "@/axios.js"
import {BButton, BFormInput, BFormSelect} from "bootstrap-vue";

export default {
  name: "edit-plant-photo",
  components: {
    JayUpload,
    BButton,
    BFormInput,
    BFormSelect,
  },
  data() {
    return {
      promptUploadPhoto: false,
      manual_status_options: [
        {value: "G", text: 'GENERATED'},
        {value: "C", text: 'CREATED'},
        {value: "U", text: 'UPDATED'},
        {value: "D", text: 'DELETED'},
        {value: "V", text: 'VALIDATED'},
      ]
    }
  },
  props: {
    plantPhoto: {
      type: Object,
      default: null
    },
  },
  methods: {
    addPhoto(photo) {
      this.plantPhoto.photo = photo
    },
    edit() {
      let payload = this.plantPhoto

      // Convert JayUpload photo to backend photo
      if (payload.photo && payload.photo.id) {
        payload.photo = payload.photo.id
      } else {
        delete payload.photo
      }
      delete payload.photo_url

      if (this.plantPhoto.id) {
        // Update
        this.$loading(true)
        axios.patch("plants/photo.admin/" + this.plantPhoto.id + "/", payload)
            .then(() => {
              this.$notify({
                color: "primary",
                title: "Photo Mise à jour",
                text: "La photo a bien été mise à jour",
                time: 4000
              })
              this.$emit('updated')
            })
            .catch(error => {
              this.$notify({
                color: "danger",
                title: "Erreur",
                text: error.message,
                time: 4000
              })
            })
            .finally(() => {
              this.$loading(false)
            })

      } else {
        // Create
        if (!payload.photo) {
          this.$notify({
            color: "warning",
            title: "Erreur",
            text: "Aucune photo sélectionnée",
            time: 4000
          })
        } else {
          this.$loading(true)
          axios.post("plants/photo.admin/", payload)
              .then(() => {
                this.$notify({
                  color: "primary",
                  title: "Photo ajoutée",
                  text: "La photo a bien été ajoutée",
                  time: 4000
                })
                this.$emit('updated')
              })
              .catch(error => {
                this.$notify({
                  color: "danger",
                  title: "Erreur",
                  text: error.message,
                  time: 4000
                })
              })
              .finally(() => {
                this.$loading(false)
              })
        }
      }
    }
  }
}
</script>
