<template>
  <b-card :key="gotPlant ? plant.id : 0" class="plant-info max-w-lg" noBody @click="clickx">
    <!-- Photo de garde -->
    <div v-if="gotPlant" class="jaya-top-row p-1 ">
      <swiper v-if="plantPhotos.length > 1" ref="swiper" :options="plantImageSwiperOption">
        <swiper-slide v-for="(photo, index) in plantPhotos" :key="index" class="d-flex justify-content-center w-100">
          <viewer ref="viewer" :images="[photo]" class="jaya-container">
            <img v-for="src in [photo]"
                :key="src"
                :alt="getAlt(plant.photos[index].author, plant.photos[index].license)"
                :src="src"
                class="jaya-image">
          </viewer>
        </swiper-slide>
        <div slot="pagination" class="swiper-pagination top-0"/>
      </swiper>
      <viewer v-else :images="plantPhotos" class="jaya-container">
        <img v-for="src in plantPhotos"
            :key="src"
            :alt="getAlt(plant.photos[0] ? plant.photos[0].author : null, plant.photos[0] ? plant.photos[0].license : null)"
            :src="src"
            class="jaya-image">
      </viewer>

      <div class="jaya-image-buttons">
        <b-button
            v-if="!isInGarden && !plantPage"
            class="ml-1"
            variant="primary"
            @click="checkInvasiveStatus(plant.id)">
          Ajouter au jardin
        </b-button>
        <b-button
            class="ml-1 p-50"
            pill
            size="sm"
            variant="primary"
            @click="copyLink">
          <feather-icon
              icon="Link2Icon"
              size="24"/>
        </b-button>
        <b-button
            v-if="(isInGarden)"
            class="ml-1 p-50"
            pill
            size="sm"
            variant="primary"
            @click="checkInvasiveStatus(plant.id)">
          <feather-icon
              icon="PlusIcon"
              size="24"/>
        </b-button>
        <b-button
            v-if="(isInGarden)"
            class="ml-1 p-50"
            pill
            size="sm"
            variant="danger"
            @click="deletePlant(gardenPlantId, plant.id)">
          <feather-icon
              icon="TrashIcon"
              size="24"/>
        </b-button>
      </div>

      <div class="jaya-image-edit-buttons">
        <b-button
            v-if="user && user.is_superuser"
            class="ml-50 p-50"
            pill
            size="sm"
            style="position: relative;"
            variant="flat-primary"
            @click="addImage">
          <feather-icon
              icon="ImageIcon"
              size="24"/>
          <strong style="font-size: 22px;">+</strong>
        </b-button>
        <b-button
            v-if="plant.photos.length > 0 && user && user.is_superuser"
            class="ml-50 p-50"
            pill
            size="sm"
            variant="flat-primary"
            @click="editImage">
          <feather-icon
              icon="EditIcon"
              size="24"/>
        </b-button>
        <b-button
            v-if="plantPhotos.length > 1"
            class="ml-50 p-50"
            pill
            size="sm"
            variant="flat-primary"
            @click="showImageViewer">
          <feather-icon
              icon="MaximizeIcon"
              size="24"/>
        </b-button>
      </div>
    </div>

    <b-card-body v-if="gotPlant">
      <b-row class="mb-1">
        <div class="ml-1">
          <span class="font-medium-5 font-weight-bolder"> {{ plant.common_name }} </span><br>
          <i class="font-medium-2"> {{ plant.scientific_name }} </i> <br>
          <span class="font-small-3" v-if="plant.synonyms && plant.synonyms.length > 0">Aussi connu comme : {{plant.synonyms.join(', ')}}</span>
        </div>
      </b-row>

      <div class="d-flex flex-column align-items-center">
        <b-alert
            v-if="plant.invasive"
            class="p-50 m-50"
            show
            variant="danger">
          <div class="d-flex align-items-center justify-content-around text-center">
            <div class="p-0">
              <img alt="Icône plante envahissante" src="@/assets/images/icons/other/invasive.svg" style="height: 30px;"
              class="px-50 pl-1">
            </div>
            <div class="p-0">
              <div>
                <span class="font-weight-bold">Cette plante est considérée comme envahissante.</span><br>
                <span class="font-weight-bold" v-if="isInGarden">Il pourrait être intéressant de la remplacer</span><br v-if="isInGarden">
              </div>
            </div>
            <div class="p-0">
              <b-button
                  size="sm"
                  class="p-0 px-50 pr-1"
                  variant="flat-danger"
                  @click="$refs.invasivePlantInfoPrompt.show()">
                <feather-icon
                    icon="InfoIcon"
                    size="24"/>
              </b-button>
            </div>
          </div>
        </b-alert>

        <b-alert
            v-if="plant.local"
            class="p-50 m-50"
            show
            variant="success">
          <div class="d-flex align-items-center justify-content-around text-center">
            <div class="p-0">
              <img alt="Icône plante indigène" src="@/assets/images/icons/other/local.svg" style="height: 30px;"
                   class="px-50 pl-1">
            </div>
            <div class="p-0">
              <div>
                Cette plante est
                <JayaTooltip
                    text="Les listes de plantes indigènes sont fournies par les collectivités territoriales partenaires.">
                  indigène
                </JayaTooltip>
                à votre territoire, c'est un atout pour la biodiversité de votre jardin
              </div>
            </div>
          </div>
        </b-alert>

        <b-alert
            v-if="plant.adapted_to_balcony"
            class="p-50 m-50"
            show
            variant="info">
          <div class="d-flex align-items-center justify-content-around text-center">
            <div class="p-0">
              <div>
                Cette plante est
                <JayaTooltip text="Elle résiste bien à la sécheresse et/ou ses racines n'ont pas besoin de beaucoup de volume de sol.
                     D'autres specificités d'un balcon (présence de vent accentuée, plus grande inertie thermique,...) ne sont pas prises en compte par ce critère. ">
                  adaptée
                </JayaTooltip>
                aux balcons et à la culture en pot
              </div>
            </div>
          </div>
        </b-alert>
      </div>

      <b-button
          v-if="plant.jaya_infos"
          class="mx-auto my-1 d-flex align-items-center py-50"
          variant="outline-dark"
          @click="$refs.goToInteractionsPrompt.show()">
        <img alt="interaction" class="mr-50" src="@/assets/images/icons/other/interaction_very_good.svg"
            style="height: 28px;">
        <span class="align-middle">Cultures associées</span>
      </b-button>

      <b-alert
          v-if="plantInfoCount < 2"
          class="text-center py-1 m-1"
          show
          variant="warning">
        Ne disposant pas assez d'informations nous ne proposons pas la fiche de cette plante pour le moment.
      </b-alert>

      <div v-else class="w-100">
        <TextBlock class="mb-1" v-if="isInterest" collapsable title="Intérêt">
          <feather-icon
              slot="titleSpace"
              icon="CheckIcon"
              size="24"/>
          <p class="text-justify" v-html="plant.rf_infos.culture_interrest" />
        </TextBlock>

        <TextBlock class="mb-1" v-if="isMelliferous" collapsable title="Plante mellifère">
          <img alt="Icone pelle" class="icon" src="@/assets/images/icons/plants/Lavandula.svg" slot="titleSpace">
          <div>
            <p class="melliferous-text">
              <strong>
                Cette plante est mellifère
              </strong>
            </p>
            <div class="melliferous-interest">
              <span style="font-style: italic"> Intérêt nectar </span>
              <img alt="Icone mellifère" src="@/assets/images/icons/plants/exposition.svg" class="icon-melliferous"
                   v-for="i in plant.global_jaya_infos.melliferous.nectar_interest" :key="i">
              <img alt="Icone mellifère grisé" src="@/assets/images/icons/plants/exposition.svg" class="icon-melliferous"
                   v-for="i in 3 - plant.global_jaya_infos.melliferous.nectar_interest" :key="3 + i" style="filter: grayscale(1)"/>
            </div>
            <div class="melliferous-interest">
              <span style="font-style: italic"> Intérêt pollen </span>
              <img alt="Icone mellifère" src="@/assets/images/icons/plants/exposition.svg" class="icon-melliferous"
                   v-for="i in plant.global_jaya_infos.melliferous.pollen_interest" :key="i">
              <img alt="Icone mellifère grisé" src="@/assets/images/icons/plants/exposition.svg" class="icon-melliferous"
                   v-for="i in 3 - plant.global_jaya_infos.melliferous.pollen_interest" :key="3 + i" style="filter: grayscale(1)"/>
            </div>
          </div>

        </TextBlock>

        <TextBlock class="mb-1" v-if="isPollenAllergy" collapsable title="Plante allergisante">
          <img alt="Icone pelle" class="icon" src="@/assets/images/icons/plants/Artemisia vulgaris.svg" slot="titleSpace">
          <div>
            <p class="melliferous-text">
              <strong>
                Cette plante peut être allergisante
              </strong>
            </p>
            <div class="melliferous-interest">
              <span style="font-style: italic"> Element allergisant :  </span> <span class="text-bold">pollen</span> <br>
              <span style="font-style: italic"> Potentiel allergisant :  </span> <span :class="'pollen-level-'+getPollenLevel[1]">
                {{ getPollenLevel[0] }}
              </span>
            </div>
          </div>

        </TextBlock>

        <TextBlock class="mb-1" v-if="isMaintenance" collapsable title="Actions à réaliser">
          <img slot="titleSpace" alt="Icone pelle" class="icon" src="@/assets/images/icons/tools/shovel.svg">

          <div v-if="isWaterings">
            <h4 class="d-flex">
              <img alt="Icon arrosage" class="icon-small" src="@/assets/images/icons/tools/watering_can.svg">
              Arrosage
            </h4>
            <div v-if="plant.rf_infos && plant.rf_infos.watering" >
              <p v-for="(watering,index) in plant.rf_infos.watering.filter(e => e.details !== '')" :key="index">
              <span v-if="plant.rf_infos.watering.filter(e => e.details !== '').length>1" class="font-italic">
                {{ watering.installation }} :
              </span>
                <span>{{ watering.details }}</span>
              </p>
            </div>
          </div>

          <div v-if="isSowings">
            <h4 class="d-flex">
              <img alt="Icon plantation" class="icon-small" src="@/assets/images/icons/other/sowing_hand.svg">
              Plantation
            </h4>
            <JayaTimeline :activeMonths="jayaPeriodsConcat(plant.jaya_infos.planting_periods)" class="max-w-lg mb-50"/>
          </div>

          <div v-if="isTrims">
            <h4 class="d-flex">
              <img alt="Icon taille" class="icon-small" src="@/assets/images/icons/tools/shear.svg">
              Taille
            </h4>
            <div v-for="(trim, indexTrim) in plant.rf_infos.trims.filter(e => isMonthsCorrect(e.periods))"
                :key="indexTrim" class="pb-50">
              <strong> {{ trim.type }} </strong>
              <br>
              <p v-if="trim.type === 'Taille sanitaire'" class="mb-50">
                Supprimer les branches mortes ainsi que celles présentant des anomalies de croissance
                ou des blessures importantes. A faire en fonction des besoins.
              </p>
              <JayaTimeline v-else :activeMonths="trim.periods |  numericMonthsArray" class="max-w-lg mb-50"/>
            </div>
          </div>

          <div v-if="isHarvests">
            <h4 class="d-flex">
              <img alt="Icon récolte" class="icon-small" src="@/assets/images/icons/tools/basket.svg">
              Récolte
            </h4>

            <div v-if="plant.jaya_infos && plant.jaya_infos.harvest_periods">
              <strong> Fruits </strong>
              <JayaTimeline :activeMonths="jayaPeriodsConcat(plant.jaya_infos.harvest_periods)" class="max-w-lg mb-50"/>
            </div>

            <div v-else-if="plant.rf_infos && plant.rf_infos.organs.length > 0">
              <div v-for="(organ, index) in plant.rf_infos.organs.filter(e => isMonthsCorrect(e.harvest_periods))"
                  :key="index" class="pb-50">
                <strong> {{ organ.name }} </strong>
                <JayaTimeline :activeMonths="organ.harvest_periods |  numericMonthsArray" class="max-w-lg mb-50"/>
              </div>
            </div>

            <div v-else>
              <strong> Fruits </strong>
              <JayaTimeline v-if="plant.usda_infos" :activeMonths="plant.usda_infos.fruit_seed_period"
                  class="max-w-lg mb-50"/>
            </div>
          </div>
        </TextBlock>

        <TextBlock class="mb-1" v-if="isOptimalConditions" collapsable title="Conditions optimales">
          <img slot="titleSpace" alt="Icone transplantoir" class="icon"
              src="@/assets/images/icons/tools/garden_trowel.svg">
          <b-list-group flush>
            <b-list-group-item
                v-if="temperature"
                class="d-flex justify-content-between px-0">
              <strong>Temperature minimum</strong>
              {{ temperature }}
            </b-list-group-item>
            <b-list-group-item
                v-if="exposition"
                class="d-flex justify-content-between px-0">
              <strong>Exposition</strong>
              {{ exposition }}
            </b-list-group-item>
            <b-list-group-item
                v-if="shade_tolerance && !exposition"
                class="d-flex justify-content-between px-0">
              <strong>Tolérance à l'ombre</strong>
              {{ shade_tolerance }}
            </b-list-group-item>
            <b-list-group-item
                v-if="fertility"
                class="d-flex justify-content-between px-0">
              <strong>Besoin en matière organique</strong>
              {{ fertility }}
            </b-list-group-item>
            <b-list-group-item
                v-if="water_need"
                class="d-flex justify-content-between px-0">
              <strong>Besoin en eau</strong>
              {{ water_need }}
            </b-list-group-item>
            <b-list-group-item
                v-if="plant.rf_infos && plant.rf_infos.humidities.length > 0"
                class="d-flex justify-content-between px-0">
              <strong>Humidité</strong>
              <div>
              <span v-for="(humidity, indexHumidity) in plant.rf_infos.humidities" :key="indexHumidity">
                <span v-if="indexHumidity">/</span>
                {{ humidity }}
              </span>
              </div>
            </b-list-group-item>
            <b-list-group-item v-if="soilTexture.length > 0" class="px-0">
              <strong>Texture du sol</strong>
              <div class="d-flex justify-content-start">
                <div
                    v-if="soilTexture.length > 0"
                    class="d-flex justify-content-start align-items-center pr-2">
                  <feather-icon
                      v-if="soilTexture.includes('fine')"
                      class="text-primary"
                      icon="CheckIcon"/>
                  <feather-icon
                      v-else
                      class="text-danger"
                      icon="XIcon"/>
                  Fin
                </div>

                <div
                    v-if="soilTexture.length > 0"
                    class="d-flex justify-content-start align-items-center pr-2">
                  <feather-icon
                      v-if="soilTexture.includes('medium')"
                      class="text-primary"
                      icon="CheckIcon"/>
                  <feather-icon
                      v-else
                      class="text-danger"
                      icon="XIcon"/>
                  Moyen
                </div>

                <div
                    v-if="soilTexture.length > 0"
                    class="d-flex justify-content-start align-items-center pr-2">
                  <feather-icon
                      v-if="soilTexture.includes('coarse')"
                      class="text-primary"
                      icon="CheckIcon"/>
                  <feather-icon
                      v-else
                      class="text-danger"
                      icon="XIcon"/>
                  Grossier
                </div>
              </div>
            </b-list-group-item>
            <b-list-group-item v-if="soilType.length > 0" class="px-0">
              <strong>Type de sol</strong>
              <div class="d-flex justify-content-start">
                <div
                    v-if="soilType.length > 0"
                    class="d-flex justify-content-start align-items-center pr-2">
                  <feather-icon
                      v-if="soilType.includes('clay')"
                      class="text-primary"
                      icon="CheckIcon"/>
                  <feather-icon
                      v-else
                      class="text-danger"
                      icon="XIcon"/>
                  Argileux
                </div>

                <div
                    v-if="soilType.length > 0"
                    class="d-flex justify-content-start align-items-center pr-2">
                  <feather-icon
                      v-if="soilType.includes('silty')"
                      class="text-primary"
                      icon="CheckIcon"/>
                  <feather-icon
                      v-else
                      class="text-danger"
                      icon="XIcon"/>
                  Limoneux
                </div>

                <div
                    v-if="soilType.length > 0"
                    class="d-flex justify-content-start align-items-center pr-2">
                  <feather-icon
                      v-if="soilType.includes('sandy')"
                      class="text-primary"
                      icon="CheckIcon"/>
                  <feather-icon
                      v-else
                      class="text-danger"
                      icon="XIcon"/>
                  Sableux
                </div>
              </div>
            </b-list-group-item>
          </b-list-group>
        </TextBlock>

        <TextBlock class="mb-1" v-if="isCharacteristics" collapsable title="Caractéristiques">
          <img slot="titleSpace" alt="Icone hauteur des plantes" class="icon"
              src="@/assets/images/icons/other/plant_height.svg">

          <b-list-group flush>
            <b-list-group-item
                v-if="category"
                class="d-flex justify-content-between px-0">
              <strong>Catégorie</strong>
              {{ category }}
            </b-list-group-item>
            <b-list-group-item
                v-if="duration"
                class="d-flex justify-content-between px-0">
              <strong>Cycle de vie</strong>
              {{ duration }}
            </b-list-group-item>
            <b-list-group-item
                v-if="plant.usda_infos && plant.usda_infos.category"
                class="d-flex justify-content-between px-0">
              <strong>Cotylédon</strong>
              {{ plant.usda_infos.category }}
            </b-list-group-item>
            <b-list-group-item
                v-if="height"
                class="d-flex justify-content-between px-0">
              <strong>Hauteur à maturité</strong>
              {{ height }}
            </b-list-group-item>
            <b-list-group-item
                v-if="rootDepth"
                class="d-flex justify-content-between px-0">
              <strong>Profondeur des racines</strong>
              {{ rootDepth }}
            </b-list-group-item>
            <b-list-group-item
                v-if="flowerColor"
                class="d-flex justify-content-between px-0">
              <strong>Couleur des fleurs</strong>
              {{ flowerColor }}
            </b-list-group-item>
          </b-list-group>

          <div
              v-if="plant.usda_infos && plant.usda_infos.active_growth_period.length > 0 && plant.usda_infos.active_growth_period.length < 12"
              class="mb-1">
            <h4 class="d-flex">
              <img alt="Icon croissance des plantes" class="icon-small"
                  src="@/assets/images/icons/other/growth_period.svg">
              Croissance
            </h4>
            <JayaTimeline :activeMonths="plant.usda_infos.active_growth_period" class="max-w-lg  mb-50"/>
          </div>

          <div v-if="floweringPeriods" class="mb-1">
            <h4 class="d-flex">
              <img alt="Icon floraison" class="icon-small" src="@/assets/images/icons/plants/default_flower.svg">
              Floraison
            </h4>
            <JayaTimeline :activeMonths="floweringPeriods" class="max-w-lg mb-50"/>
          </div>
        </TextBlock>

        <TextBlock class="mb-1" v-if="isBiodiversity" collapsable title="Biodiversité">
          <img slot="titleSpace" alt="Icone hauteur des plantes" class="icon"
              src="@/assets/images/icons/fauna/butterfly.svg">

          <div v-if="plant.rf_infos && plant.rf_infos.biodiversity.length > 0">
            <p v-for="(biodiversity, index) in plant.rf_infos.biodiversity" :key="index">
              <span v-html="biodiversity.benefic_effect"/> pour les
              <span v-html="biodiversity.concerned_spicies.toLowerCase()"/><br>
            </p>
          </div>

          <div v-else>
            <p v-if="plant.pollinators && plant.pollinators.length > 0">
              Pollinisé par les
              <span v-for="(pollinator, indexP) in plant.pollinators" :key="indexP">
              {{
                  indexP === plant.pollinators.length - 1 ? pollinator.toLowerCase() + '.' : pollinator.toLowerCase() + ', '
                }}
            </span>
            </p>
            <p v-if="plant.host_for && plant.host_for.length > 0">
              Hôte pour les
              <span v-for="(hosted, index) in plant.host_for" :key="index">
              {{ index === plant.host_for.length - 1 ? hosted.toLowerCase() + '.' : hosted.toLowerCase() + ', ' }}
            </span>
            </p>
          </div>
          <div v-if="plant.biotic_interactions && plant.biotic_interactions.length > 0">
            <div class="d-flex mb-2 justify-content-start align-items-center">
              <JayaTooltip title="Interactions biotiques" class="pr-50">
                <feather-icon
                    icon="InfoIcon"
                    class="text-info"
                    size="20"/>
                <div slot="text">
                  <p>
                    Les données d'interaction entre espèces proviennent du programme "GloBI".
                    Global Biotic Interactions (GloBI) offre un accès libre à des données sur les interactions entre
                    espèces (par exemple, prédateur-proie, pollinisateur-plante, pathogène-hôte, parasite-hôte) en
                    combinant des ensembles de données ouverts existants à l'aide d'un logiciel libre.
                  </p>
                  <p>
                    Vous trouverez ici seulement les interactions entre espèces présentes dans les bases de données
                    faunistique et floristique de Hortilio.
                  </p>
                  <i>
                    Réference :
                    Jorrit H. Poelen, James D. Simons and Chris J. Mungall. (2014). Global Biotic Interactions:
                    An open infrastructure to share and analyze species-interaction datasets. Ecological Informatics.
                    <a class="cursor-pointer text-primary" @click="$openLinkJaya('https://doi.org/10.1016/j.ecoinf.2014.08.005')" target="_blank">https://doi.org/10.1016/j.ecoinf.2014.08.005.</a>
                  </i>
                </div>
              </JayaTooltip>
              <h4 class="plant-info mb-0">Interactions remarquables
              </h4>
            </div>
            <div v-for="(interaction, interactionIndex) in plant.biotic_interactions.filter(e => e.taxa.length && e.taxa.length > 0)" :key="interactionIndex" class="mb-1">
              <h5 class="mb-0"><span class="text-secondary">{{ formatInteractionName(interaction.interaction_type.verb_fr) }} :</span></h5>
              <b-list-group flush>
                <b-list-group-item
                    v-for="(taxon, index) in (expandedInteractions[interactionIndex] ? interaction.taxa : interaction.taxa.slice(0, 3))"
                    :key="`taxon-${index}`"
                    class="px-0 py-0">
                  <div v-if="taxon.animal !== null" class="d-flex justify-content-between align-items-center">
                    <div>
                      {{ formatTaxonName(taxon.animal.common_name) }}<br>
                    </div>
                    <b-button
                        class="p-25"
                        pill
                        variant="flat-dark"
                        @click="$router.push({name: 'animals', params: {animalId: taxon.animal.id}})">
                      <feather-icon
                          slot="titleSpace"
                          icon="ArrowRightCircleIcon"
                          size="20"/>
                    </b-button>
                  </div>
                  <div v-else-if="taxon.generic_plant !== null" class="d-flex justify-content-between align-items-center">
                    <div>
                      {{ formatTaxonName(taxon.generic_plant.common_name) }}<br>
                    </div>
                    <b-button
                        class="p-0"
                        pill
                        variant="flat-dark"
                        @click="$router.push({name: 'plants', params: {plantId: taxon.generic_plant.id}})">
                      <feather-icon
                          slot="titleSpace"
                          icon="ArrowRightCircleIcon"
                          size="20"/>
                    </b-button>
                  </div>
                  <div v-else>
                    <i>{{ taxon.lb_nom }}</i>
                  </div>
                </b-list-group-item>

                <b-list-group-item class="ml-2 text-primary px-0 pt-50" v-if="interaction.taxa.length > 5">
                  <b-button
                      class="p-0 font-weight-normal"
                      pill
                      @click="toggleInteractionDisplay(interactionIndex)"
                      variant="flat-secondary">
                    <span v-if="expandedInteractions[interactionIndex]">Voir moins</span>
                    <span v-else>Voir tout ({{ interaction.taxa.length }})</span>
                  </b-button>
                </b-list-group-item>
              </b-list-group>
            </div>
          </div>
        </TextBlock>

        <TextBlock class="mb-1"
            v-if="isThreat"
            collapsable
            title="Dangers">
          <feather-icon
              slot="titleSpace"
              class="text-danger"
              icon="AlertTriangleIcon"
              size="24"/>
          <app-collapse>
            <strong
                v-if="plant.rf_infos && plant.rf_infos.bacteries.length>0"
                class="text-danger">
              Bacteries
            </strong>
            <app-collapse-item
                v-for="danger in plant.rf_infos.bacteries"
                :key="danger.vernacular_name"
                :title="danger.vernacular_name">
              <div class="pt-50 pl-50">{{ danger.description }}</div>
            </app-collapse-item>
            <strong
                v-if="plant.rf_infos && plant.rf_infos.bioagressors.length>0"
                class="text-danger">
              Bioagresseurs
            </strong>
            <app-collapse-item
                v-for="danger in plant.rf_infos.bioagressors"
                :key="danger.vernacular_name"
                :title="danger.vernacular_name">
              <div class="pt-50 pl-50">{{ danger.description }}</div>
            </app-collapse-item>
            <strong
                v-if="plant.rf_infos && plant.rf_infos.mushrooms.length>0"
                class="text-danger">
              Champignons
            </strong>
            <app-collapse-item
                v-for="danger in plant.rf_infos.mushrooms"
                :key="danger.vernacular_name"
                :title="danger.vernacular_name">
              <div class="pt-50 pl-50">{{ danger.description }}</div>
            </app-collapse-item>
            <strong
                v-if="plant.rf_infos && plant.rf_infos.viruses.length>0"
                class="text-danger">
              Virus
            </strong>
            <app-collapse-item
                v-for="danger in plant.rf_infos.viruses"
                :key="danger.vernacular_name"
                :title="danger.vernacular_name">
              <div class="pt-50 pl-50">{{ danger.description }}</div>
            </app-collapse-item>
          </app-collapse>
        </TextBlock>

        <TextBlock class="mb-1" v-if="isFamily" collapsable title="Famille">
          <img slot="titleSpace" alt="Icone croissance des plantes" class="icon"
              src="@/assets/images/icons/other/family.svg">

          <b-list-group flush>
            <b-list-group-item
                v-if="plant.parent_taxon"
                class="d-flex justify-content-between px-0">
              <div>
                <strong> {{ plant.parent_taxon.common_name }} </strong><br>
                {{ plant.parent_taxon.scientific_name }}
              </div>
              <b-button
                  class="p-0"
                  pill
                  variant="flat-dark"
                  @click="newPlantInfo(plant.parent_taxon.id)">
                <feather-icon
                    slot="titleSpace"
                    icon="ArrowRightCircleIcon"
                    size="24"/>
              </b-button>
            </b-list-group-item>

            <b-list-group-item class="ml-2 text-primary px-0">
              <strong> {{ plant.common_name }} </strong><br>
              {{ plant.scientific_name }}
            </b-list-group-item>

            <b-list-group-item
                v-for="child in limitList(plant.children_taxons)"
                :key="child.id"
                class="ml-4 d-flex justify-content-between px-0">
              <div>
                <strong> {{ child.common_name }} </strong><br>
                {{ child.scientific_name }}
              </div>
              <b-button
                  class="p-0"
                  pill
                  variant="flat-dark"
                  @click="newPlantInfo(child.id)">
                <feather-icon
                    slot="titleSpace"
                    icon="ArrowRightCircleIcon"
                    size="24"/>
              </b-button>
            </b-list-group-item>

            <b-list-group-item v-if="!showAll && plant.children_taxons.length > 2" class="ml-4 text-primary px-0">
              <b-button
                  class="p-0"
                  pill
                  variant="flat-secondary"
                  @click="showAllFamily">
                <feather-icon
                    slot="titleSpace"
                    icon="MoreHorizontalIcon"
                    size="24"/>
              </b-button>
            </b-list-group-item>

            <b-list-group-item
                v-for="sibling in limitList(plant.siblings_taxons).filter(e => e.scientific_name !== plant.scientific_name)"
                :key="sibling.id"
                class="ml-2 d-flex justify-content-between px-0">
              <div>
                <strong> {{ sibling.common_name }} </strong><br>
                {{ sibling.scientific_name }}
              </div>
              <b-button
                  class="p-0"
                  pill
                  variant="flat-dark"
                  @click="newPlantInfo(sibling.id)">
                <feather-icon
                    slot="titleSpace"
                    icon="ArrowRightCircleIcon"
                    size="24"/>
              </b-button>
            </b-list-group-item>

            <b-list-group-item v-if="!showAll && plant.siblings_taxons.length > 2" class="ml-2 text-primary px-0">
              <b-button
                  class="p-0"
                  pill
                  variant="flat-secondary"
                  @click="showAllFamily">
                <feather-icon
                    slot="titleSpace"
                    icon="MoreHorizontalIcon"
                    size="24"/>
              </b-button>
            </b-list-group-item>
          </b-list-group>
        </TextBlock>
      </div>
    </b-card-body>

    <b-modal
        ref="addInvasivePlantPrompt"
        cancel-title="Annuler"
        centered
        ok-title="Ajouter quand même"
        ok-variant="primary"
        title="Cette plante est considérée comme envahissante"
        @ok="addPlant(plant.id)">
      <p>
        Si vous prévoyez de l'ajouter réellement à votre jardin, réfléchissez-y à deux fois : c'est une menace
        pour la biodiversité...
      </p>
      <p>
        Si elle se trouve déjà dans votre jardin, vous pouvez l'y ajouter.
      </p>
    </b-modal>

    <b-modal
        ref="goToInteractionsPrompt"
        cancel-title="Annuler"
        cancel-variant="outline-danger"
        centered
        modal-class="modal-danger"
        ok-title="Continuer"
        ok-variant="primary"
        title="Charger les cultures associées ?"
        @ok="exploreInteractions">
      Attention charger les cultures associées vous fera quitter votre recherche actuelle.
      <br>
      Etes-vous sur de continuer ?
    </b-modal>

    <b-modal
        ref="popUpEditPhoto"
        centered
        hide-footer
        title="Plant Photo">
      <EditPlantPhoto :plantPhoto="popUpPlantPhoto" @updated="updatePlantPhoto"/>
    </b-modal>

    <b-modal
        ref="invasivePlantInfoPrompt"
        centered
        hide-footer
        title="Espèces exotiques envahissantes">

      <h4>Qu'est ce qu'une espèce exotique envahissante ?</h4>
      <p>
        Une espèce exotique envahissante (EEE) est une espèce introduite par l’homme volontairement ou involontairement
        sur un territoire hors de son aire de répartition naturelle, et qui menace les écosystèmes, les habitats
        naturels ou les espèces locales.
      </p>

      <h4>Quels sont les risques ?</h4>
      <p>
        Ces espèces représentent une menace pour les espèces locales, car elles accaparent une part trop importante des
        ressources (espace, lumière, ressources alimentaires, habitat…) dont les autres espèces ont besoin pour
        survivre. Elles peuvent aussi être prédatrices directes des espèces locales. Les espèces exotiques
        envahissantes sont aujourd’hui considérées comme l’une des principales menaces pour la biodiversité.
        Elles constituent un danger pour environ un tiers des espèces terrestres et ont contribué à près de la moitié
        des extinctions connues à l’échelle mondiale.
      </p>
      <p>
        <span class="font-italic">Ces informations proviennent d'une source gouvernementale.</span>
        <a class="text-primary" @click="$openLinkJaya('https://www.ecologie.gouv.fr/especes-exotiques-envahissantes')">
          Voir le site pour en savoir plus.
        </a>
      </p>
    </b-modal>
    <AppRating :showModal="showRating"/>
  </b-card>
</template>

<script type="text/javascript">
import {BAlert, BButton, BCard, BCardBody, BListGroup, BListGroupItem, BModal, BRow} from "bootstrap-vue";
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import TextBlock from '@/components/global/JayaTextBlock.vue'
import JayaTimeline from '@/components/global/JayaTimeline.vue'
import {Swiper, SwiperSlide} from 'vue-awesome-swiper'
import EditPlantPhoto from '@/components/plants/EditPlantPhoto.vue'
import {mapState} from "vuex";
import AppRating from '@/components/global/AppRating.vue'

export default {
  components: {
    AppRating,
    BCard,
    BCardBody,
    BButton,
    BRow,
    BAlert,
    BListGroup,
    BListGroupItem,
    BModal,
    AppCollapse,
    AppCollapseItem,
    TextBlock,
    JayaTimeline,
    EditPlantPhoto,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      popUpBigPictures: false,
      popUpPlantPhoto: {
        id: null,
        author: '',
        source: '',
        license: '',
        order: 0,
        manual_status: null,
        photo: null,
      },
      gotPlant: false,
      plantPageId: -1,
      showAll: false,
      colors: {
        White: "Blanc",
        Green: "Vert",
        Red: "Rouge",
        Purple: "Violet",
        Brown: "Marron",
        Blue: "Bleu",
        Yellow: "Jaune",
        Orange: "Orange"
      },
      plantImageSwiperOption: {
        direction: 'horizontal',
        slidesPerView: 1,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        }
      },
      plant: null,
      showRating: false,
      expandedInteractions: {},
    }
  },
  props: {
    title: {
      type: String,
      default: "Default Title"
    },
    thumbnail: {
      type: String,
      default: "feuille"
    },
    plantId: {
      type: Number,
      default: -1
    },
    isInGarden: {
      type: Boolean,
      default: false
    },
    gardenPlantId: {
      type: Number,
      default: -1
    },
    plantPage: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    this.plantPageId = this.plantId
  },
  computed: {
    ...mapState({
      user: state => state.account.user,
    }),
    plantPhotos() {
      this.gotPlant // Force update plantPhoto when getPlant change
      if (this.plant.photos.length > 0) {
        return this.plant.photos
            .map(photo => process.env.VUE_APP_BACKEND_URL + '/..' + photo.photo_url + "&size=600")
      }
      if (this.plant.pictos.length > 0) {
        return [require("@/assets/images/icons/plants/" + this.plant.pictos[0]["name"])]
      }
      return [require("@/assets/images/default_pictures/plant_default.svg")]
    },

    getPollenLevel() {
      if (!this.plant.global_jaya_infos || this.plant.global_jaya_infos.allergies.filter(a=>a.type===1).length === 0) {
        return ['unknown', -1]
      }
      else {
        const pollenAllergy = this.plant.global_jaya_infos.allergies.filter(a=>a.type===1)[0].level
        if (pollenAllergy === 1) {
          return ['faible', 1]
        }
        if (pollenAllergy === 2) {
          return ['modéré', 2]
        }
        if (pollenAllergy === 3) {
          return ['fort', 3]
        }
        return ['unknown', -1]
      }
    },
    isInterest() {
      return this.plant.rf_infos && this.plant.rf_infos.culture_interrest
    },
    isMaintenance() {
      return this.isWaterings || this.isTrims || this.isHarvests || this.isSowings
    },
    isWaterings() {
      return (this.plant.rf_infos && this.plant.rf_infos.watering)
    },
    isTrims() {
      if (this.gotPlant && this.plant.rf_infos && this.plant.rf_infos.trims.length > 0) {
        //make sure not all trims have incorrect dates
        let counter = 0
        for (const trim of this.plant.rf_infos.trims) {
          if (!this.isMonthsCorrect(trim.periods)) {
            counter++
          }
        }
        return counter !== this.plant.rf_infos.trims.length
      }
      return false
    },
    isHarvests() {
      if (this.plant.jaya_infos && this.plant.jaya_infos.harvest_periods) {
        return true
      } else if (this.plant.rf_infos && this.plant.rf_infos.organs.length > 0) {
        //make sure not all organs have incorrect dates
        let counter = 0
        for (const organ of this.plant.rf_infos.organs) {
          if (!this.isMonthsCorrect(organ.harvest_periods)) {
            counter++
          }
        }
        return counter !== this.plant.rf_infos.organs.length
      } else if (this.plant.usda_infos && this.plant.usda_infos.fruit_seed_period && this.plant.usda_infos.fruit_seed_period.length > 0 && this.plant.usda_infos.fruit_seed_period.length < 12) {
        return true
      }
      return false
    },
    isSowings() {
      return (this.plant.jaya_infos && this.plant.jaya_infos.planting_periods)
    },

    isOptimalConditions() {
      return this.temperature
          || this.exposition
          || this.shade_tolerance
          || this.fertility
          || (this.plant.rf_infos && this.plant.rf_infos.humidities.length > 0)
          || this.soilTexture.length > 0
          || this.soilType.length > 0
          || this.water_need
    },
    isMelliferous() {
      return this.plant.melliferous
    },
    isPollenAllergy() {
      return this.plant.global_jaya_infos && this.plant.global_jaya_infos.allergies.filter(a => a.type === 1).length > 0
    },
    isBiodiversity() {
      return (this.plant.rf_infos && this.plant.rf_infos.biodiversity.length > 0) || this.plant.pollinators.length > 0 ||
          this.plant.host_for.length > 0 || (this.plant.biotic_interactions && this.plant.biotic_interactions.length > 0)
    },
    isThreat() {
      return this.plant.rf_infos && (this.plant.rf_infos.mushrooms.length > 0
          || this.plant.rf_infos.bacteries.length > 0 || this.plant.rf_infos.bioagressors.length > 0
          || this.plant.rf_infos.viruses.length > 0)
    },
    temperature() {
      if (this.plant.rf_infos && this.plant.rf_infos.hardiness_zone) {
        return this.plant.rf_infos.hardiness_zone.start_temperature + '°C à ' + this.plant.rf_infos.hardiness_zone.end_temperature + '°C'
      } else if (this.plant.usda_infos && this.plant.usda_infos.temperature_minimum) {
        return Math.round(this.plant.usda_infos.temperature_minimum) + '°C'
      } else {
        return false
      }
    },
    exposition() {
      if (this.plant.jaya_infos && this.plant.jaya_infos.shade_tolerance) {
        return this.plant.jaya_infos.shade_tolerance
            .replace('SU', 'Soleil').replace('HS', 'Mi-ombre').replace('SH', 'Ombre')
      } else if (this.plant.rf_infos && this.plant.rf_infos.expositions.length > 0) {
        return this.plant.rf_infos.expositions.join(', ')
      } else {
        return false
      }
    },
    shade_tolerance() {
      if (this.plant.usda_infos && this.plant.usda_infos.shade_tolerance) {
        if (this.plant.usda_infos && this.plant.usda_infos.shade_tolerance === "Intolerant") {
          return 'Intolérante'
        } else if (this.plant.usda_infos && this.plant.usda_infos.shade_tolerance === "Intermediate") {
          return 'Intermédiaire'
        } else if (this.plant.usda_infos && this.plant.usda_infos.shade_tolerance === "Tolerant") {
          return 'Tolérante'
        } else {
          return false
        }
      } else {
        return false;
      }
    },
    fertility() {
      if (this.plant.usda_infos && this.plant.usda_infos.fertility_requirement === "Low") {
        return 'Faible'
      } else if (this.plant.usda_infos && this.plant.usda_infos.fertility_requirement === "Medium") {
        return 'Moyen'
      } else if (this.plant.usda_infos && this.plant.usda_infos.fertility_requirement === "High") {
        return 'Fort'
      } else {
        return false
      }
    },
    soilTexture() {
      let results = []
      if (this.plant.usda_infos) {
        if (this.plant.usda_infos.adapted_to_fine_textured_soils) {
          results.push('fine')
        }
        if (this.plant.usda_infos.adapted_to_medium_textured_soils) {
          results.push('medium')
        }
        if (this.plant.usda_infos.adapted_to_coarse_textured_soils) {
          results.push('coarse')
        }
      }
      return results
    },
    soilType() {
      let results = []
      if (this.plant.jaya_infos) {
        if (this.plant.jaya_infos.adapted_to_clay_soil) {
          results.push('clay')
        }
        if (this.plant.jaya_infos.adapted_to_silty_soil) {
          results.push('silty')
        }
        if (this.plant.jaya_infos.adapted_to_sandy_soil) {
          results.push('sandy')
        }
      }
      return results
    },
    water_need() {
      if (this.plant.jaya_infos && this.plant.jaya_infos.water_needs) {
        return this.plant.jaya_infos.water_needs === 1 ? "Faible" : this.plant.jaya_infos.water_needs === 2 ? "Intermédiaire" :
            "Fort"
      } else {
        return false
      }
    },
    isCharacteristics() {
      return this.category
          || this.duration
          || (this.plant.usda_infos && this.plant.usda_infos.category)
          || this.height
          || this.rootDepth
          || this.flowerColor
          || (this.plant.usda_infos && this.plant.usda_infos.active_growth_period.length > 0)
          || this.floweringPeriods
    },
    isFamily() {
      return (this.plant.parent_taxon || this.plant.children_taxons.length > 0 || this.plant.siblings_taxons.length > 0)
    },
    duration() {
      if (!this.plant.usda_infos || this.plant.usda_infos.duration === '') {
        return false
      }
      var durations = this.plant.usda_infos.duration.split(', ')
      var result = ''
      for (var i = 0; i < durations.length; i++) {
        if (durations[i] === "Biennial") {
          if (result !== '') {
            result += ', '
          }
          result += 'Bisannuelle'
        } else if (durations[i] === "Annual") {
          if (result !== '') {
            result += ', '
          }
          result += 'Annuelle'
        } else if (durations[i] === "Perennial") {
          if (result !== '') {
            result += ', '
          }
          result += 'Vivace'
        }
      }
      return result
    },
    category() {
      if (this.plant.rf_infos && this.plant.rf_infos.commercial_category !== '') {
        return this.plant.rf_infos.commercial_category
      } else if (this.plant.usda_infos) {
        if (this.plant.usda_infos.growth_habit === '') {
          return false
        } else if (this.plant.usda_infos.growth_habit === "Forb/herb") {
          return 'Herbacée'
        } else if (this.plant.usda_infos.growth_habit === "Graminoid") {
          return 'Graminée'
        } else if (this.plant.usda_infos.growth_habit === "Lichenous") {
          return 'Lichen'
        } else if (this.plant.usda_infos.growth_habit === "Shrub") {
          return 'Arbuste'
        } else if (this.plant.usda_infos.growth_habit === "Subshrub") {
          return 'Arbuste'
        } else if (this.plant.usda_infos.growth_habit === "Tree") {
          return 'Arbre'
        } else if (this.plant.usda_infos.growth_habit === "Vine") {
          return 'Grimpante'
        } else {
          return false
        }
      } else {
        return false
      }
    },
    height() {
      if (this.plant.usda_infos && this.plant.usda_infos.height_mature && (this.plant.usda_infos.height_mature !== 0)) {
        return this.truncateSize(this.plant.usda_infos.height_mature) + ' m'
      }
      return false
    },
    rootDepth() {
      if (this.plant.usda_infos && this.plant.usda_infos.root_depth_minimum && (this.plant.usda_infos.root_depth_minimum !== 0)) {
        return this.truncateSize(this.plant.usda_infos.root_depth_minimum) + ' m'
      }
      return false
    },
    flowerColor() {
      if (this.plant.usda_infos && this.plant.usda_infos.flower_color !== '') {
        return this.colors[this.plant.usda_infos.flower_color]
      }
      return false
    },
    floweringPeriods() {
      if (this.plant.jaya_infos && this.plant.jaya_infos.flowering_periods) {
        return this.jayaPeriodsConcat(this.plant.jaya_infos.flowering_periods)
      } else if (this.plant.rf_infos && this.plant.rf_infos.flowering_periods.length > 0) {
        return this.$options.filters.numericMonthsArray(this.plant.rf_infos.flowering_periods)
      } else {
        return false
      }
    },

    plantInfoCount() {
      return ((this.isInterest ? 1 : 0) +
          (this.isMelliferous ? 1 : 0) +
          (this.isPollenAllergy ? 1 : 0) +
          (this.isMaintenance ? 1 : 0) +
          (this.isOptimalConditions ? 1 : 0) +
          (this.isCharacteristics ? 1 : 0) +
          (this.isBiodiversity ? 1 : 0) +
          (this.isThreat ? 1 : 0) +
          (this.isFamily ? 1 : 0))
    }
  },
  methods: {
    copyLink() {
      this.$copyLink("plants", this.plant.id)
    },
    checkInvasiveStatus(plantId) {
      if (this.plant.invasive) {
        this.$refs.addInvasivePlantPrompt.show()
      } else {
        this.addPlant(plantId)
      }
    },
    addPlant(plant) {
      const obj = {
        plant: plant, garden: this.$store.state.garden.gardens.find(gd => {
          return gd.is_active
        }).id
      }
      this.$store.dispatch("garden/addPlant", obj)
          .then(() => {
            this.$notify({
              time: 4000,
              color: "primary",
              title: "Plante ajoutée",
              text: this.plant.common_name + " ajoutée à votre jardin"
            })
            this.$store.dispatch("feedback/getScore", {
              garden: this.$store.state.garden.gardens.find(garden => {
                return garden.is_active
              })["id"],
              plant: plant, check_score: "better"
            })
                .then(response => {
                  if (response.data[0].better_biodiv_score) {
                    this.$notify({
                      time: 5000,
                      color: "primary",
                      title: "Score de biodiversité amélioré",
                      text: "Rendez vous l'onglet biodiversité pour découvrir comment améliorer encore votre score"
                    })
                  }
                })
            if (window.cordova) {
              if (this.$store.state.garden.gardens.find(gd => {
                return gd.is_active
              }).plants.length > 5) {
                this.showRating=true
              }
            }
          })
    },
    deletePlant(id, plant_id) {
      const obj = {
        id: id, garden: this.$store.state.garden.gardens.find(garden => {
          return garden.is_active
        }).id
      }
      this.$store.dispatch("garden/removePlant", obj)
          .then(() => {
            this.$notify({
              time: 4000,
              color: "warning",
              title: "Plante supprimée",
              text: this.plant.common_name + " retirée de votre jardin"
            })
            this.$store.dispatch("feedback/getScore", {
              garden: this.$store.state.garden.gardens.find(garden => {
                return garden.is_active
              })["id"],
              plant: plant_id, check_score: "worse"
            })
                .then(response => {
                  if (response.data[0].worse_biodiv_score) {
                    this.$notify({
                      time: 5000,
                      color: "warning",
                      title: "Votre score de biodiversité a baissé",
                      text: "Le nombre de strates végétales de votre jardin a baissé suite au retrait de " + this.plant.common_name + ". Rendez vous sur l'onglet biodiversité pour apprendre comment le faire augmenter."
                    })
                    this.$store.commit("feedback/SET_UPDATE_BIODIV_SCORE", 'update_score')
                  }
                })
          })
          .catch(error => {
            if (error.response && error.response.status == 403) {
              if (error.response.data.detail == "Vegetable Garden Plant") {
                this.$notify({
                  time: 6000,
                  color: "danger",
                  title: "Plante potagère",
                  text: "Cette plante est dans un potager veuillez vous rendre sur sa carte de placement pour l'y supprimer"
                })
              }
            }
          })
    },
    AllergySensibilityCount(int) {
      let result;
      result = int * 10 / 2
      return result
    },
    clickx(e) {
      e.stopPropagation()
    },
    newPlantInfo(newId) {
      this.plantPageId = newId
      this.showAll = false
    },
    limitList(array) {
      if (array.length > 2 && !this.showAll) {
        return array.slice(0, 2)
      }
      return array
    },
    truncateSize(height) {
      if (height < 10) {
        return Math.round(height * 10) / 10
      }
      return Math.round(height)
    },
    showAllFamily() {
      this.showAll = true
    },
    isMonthsCorrect(monthsArray) {
      return this.$options.filters.numericMonthsArray(monthsArray).length > 0
    },
    showImageViewer() {
      let index = this.$refs.swiper.swiper.activeIndex
      this.$refs.viewer[index].$el.viewer.show()
    },
    getAlt(author, licence) {
      if (author && licence) {
        return 'Photo de ' + author + ', ' + licence
      } else if (author) {
        return 'Photo de ' + author
      }
      return licence ? licence : ''
    },
    editImage() {
      let index = 0
      if (this.$refs.swiper) {
        index = this.$refs.swiper.swiper.activeIndex
      }
      this.popUpPlantPhoto = this.plant.photos[index]
      // Add photo
      if (this.popUpPlantPhoto.photo_url) {
        this.popUpPlantPhoto.photo = {
          url: process.env.VUE_APP_BACKEND_URL + '/..' + this.popUpPlantPhoto.photo_url
        }
      }
      // Show edit popup
      this.$refs.popUpEditPhoto.show()
    },
    addImage() {
      let order = 0
      // Compute last order photo
      if (this.plant.photos.length > 0) {
        let index = this.plant.photos.length - 1
        order = this.plant.photos[index].order
      }
      // Create empty plant photo data
      this.popUpPlantPhoto = {
        plant: this.plant.id,
        author: '',
        source: '',
        license: '',
        order: order,
        manual_status: 'C',
        photo: null,
      }
      // Show edit popup
      this.$refs.popUpEditPhoto.show()
    },
    updatePlantPhoto() {
      // Close popup
      this.$refs.popUpEditPhoto.hide()
      // Force update photos
      this.plantPageId = -1
      setTimeout(() => this.plantPageId = this.plant.id, 100)
    },
    exploreInteractions() {
      let plantIdList = []
      if (!localStorage.interactions_module_id_list) {
        localStorage.setItem('interactions_module_id_list', JSON.stringify([]))
      } else {
        plantIdList = JSON.parse(localStorage.interactions_module_id_list)
      }
      if (!plantIdList.includes(this.plant.id)) {
        plantIdList.push(this.plant.id)
      }
      localStorage.setItem('interactions_module_id_list', JSON.stringify(plantIdList))
      this.$router.push({name: 'interactions-explorer'})
    },
    jayaPeriodsConcat(array_of_array){
      let allMonths = []
      array_of_array.forEach((p) => {
        const start = Math.min(...p)
        const end = Math.max(...p)
        const months = Array.from(
            { length: (end - start) + 1 },
            (value, index) => start + index
        );
        allMonths = allMonths.concat(months)
      })
      return [...new Set(allMonths)]
    },

    formatInteractionName(interactionName) {
      return `${interactionName.charAt(0).toUpperCase()}${interactionName.slice(1)}`;
    },

    formatTaxonName(taxonName) {
      const nameWithoutParentheses = taxonName.replace(/\(.*?\)/g, '');
      return nameWithoutParentheses.charAt(0).toUpperCase() + nameWithoutParentheses.slice(1);
    },

    toggleInteractionDisplay(interactionId) {
      this.$set(this.expandedInteractions, interactionId, !this.expandedInteractions[interactionId]);
    },
  },
  watch: {
    plantPageId(id) {
      if (id >= 0) {
        this.plant = []
        this.gotPlant = false
        this.$store.dispatch("plant/get", {id: this.plantPageId})
            .then(response => {
              this.plant = response.data
              this.gotPlant = true
            })
            .catch((error) => {
              this.plant = []
              if (this.plantPage) {
                if (error.response.status == 404) {
                  this.$router.push({name: "error-404"})
                }
                if (error.response.status == 429) {
                  this.$notify({
                    time: 10000,
                    title: "Limite atteinte",
                    text: "Vous avez atteint la limite quotidienne de visualisation de plantes, pour continuer, veuillez créer un compte"
                  })
                  this.$router.push({name: "accueil"})
                }
              } else {
                this.$notify({
                  time: 4000,
                  title: "Erreur",
                  text: "Une erreur est survenue au chargement de cette plante.",
                  color: "danger"
                })
              }
            })
      }
    }
  }
}
</script>

<style lang="scss">
.swiper-box {
  z-index: 0;
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

.plant-info {
  width: 100%;
  .pollen-level-1 {
    color: var(--primary)
  }
  .pollen-level-2 {
    color: var(--warning)
  }
  .pollen-level-3 {
    color: var(--danger)
  }
  .card {
    background-color: transparent;
    box-shadow: none;

    .card-header {
      padding-bottom: 0;
    }

    .card-body {
      padding-top: 0;
    }
  }

  .list-group .list-group-item {
    background-color: transparent;
  }


  .icon {
    height: 30px;
  }

  .icon-small {
    height: 20px;
    width: 30px;
    padding-right: 5px;
  }

  .melliferous-interest{
    margin-left: 1rem;
    margin-bottom: 0.3rem;
  }

  .melliferous-text {
    margin-bottom: 0.5rem;
  }

  .icon-melliferous {
    height: 30px;
    width: 30px;
    padding-right: 5px;
  }

  .truncate-slot {
    .vs-list--slot {
      text-align: right;
      padding-left: 5px;
    }

    .list-titles {
      width: 100%
    }
  }

  h4 {
    color: rgba(var(--vs-dark), 1) !important;
    font-weight: 500;
    padding-top: 5px;
  }
}
</style>
