<template>
  <b-modal
      v-model="displayModal"
      cancel-title="Non merci"
      centered
      ok-title="Evaluer"
      ok-variant="primary"
      title="Evaluer Hortilio"
      variant="Evaluer"
      @ok="openStoreLink">
    Si Hortilio vous plaît, pourriez-vous prendre un moment pour nous aider ? Cela ne prendra pas plus d'une minute. Merci pour le coup de pousse !
  </b-modal>
</template>
<script type="text/javascript">
import {BModal} from 'bootstrap-vue'
export default {
  name: "app-rating",
  components: {BModal},

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return { displayModal: false}
  },
  methods: {
    openStoreLink() {
      let appLink = window.cordova && window.cordova.platformId === "android" ? 'https://play.google.com/store/apps/details?id=fr.jaya.hortilio' : 'https://apps.apple.com/fr/app/hortilio/id1539173291'
      this.$openLinkJaya(appLink)
    }
  },
  watch: {
    showModal(value) {
      const display_rate_date = new Date(localStorage.getItem("display_rate_date"));
      const delta_t_display_rate = new Date().getTime() - display_rate_date.getTime();
      if (value && delta_t_display_rate > 3600 * 30 * 24 * 1000 *4) {
        this.displayModal = true
        const today = new Date();
        localStorage.setItem("display_rate_date", today.toString());
      }
    }
  }
}
</script>

<style scoped>
.page-connection {
  width: 100vw;
  height: 100vh;
  background-color: var(--dark);
}
</style>