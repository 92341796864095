<template>
  <div v-if="activeMonths" class="jaya-timeline col-12 text-center">
    <b-row>
      <b-col
          v-for="(month, index) in months"
          :key="index"
          :class="{'month-on': activeMonths.includes(month.number)}"
          class="d-flex justify-content-center p-0"
          cols="1">
        {{ month.string }}
      </b-col>
    </b-row>
  </div>
</template>

<script type="text/javascript">
import {BCol, BRow} from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
  },
  data() {
    return {
      months: [
        {number: 1, string: 'J'},
        {number: 2, string: 'F'},
        {number: 3, string: 'M'},
        {number: 4, string: 'A'},
        {number: 5, string: 'M'},
        {number: 6, string: 'J'},
        {number: 7, string: 'J'},
        {number: 8, string: 'A'},
        {number: 9, string: 'S'},
        {number: 10, string: 'O'},
        {number: 11, string: 'N'},
        {number: 12, string: 'D'},
      ]
    }
  },
  props: {
    activeMonths: {
      type: Array,
    },
  },
}
</script>

<style lang="scss">
.jaya-timeline {
  .month-on {
    color: #FFFFFF;
    border: 1px solid var(--secondary);
    background-color: var(--secondary);
  }
}
</style>
